// https://stackoverflow.com/questions/4825683/how-do-i-create-and-read-a-value-from-cookie-with-javascript/70118256#70118256
export function getCookie(name: string) {
  if (typeof document === 'undefined' || !name) {
    return
  }

  const nameEQ = name + '='
  for (const cookie of document.cookie.split('; ')) {
    if (cookie.indexOf(nameEQ) === 0) {
      const value = cookie.substring(nameEQ.length)
      return decodeURIComponent(value)
    }
  }
  return
}
